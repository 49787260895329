import React from "react"
import { graphql } from "gatsby"

import Layout from "../components/templates/layoutWithTitle"
import SEO from "../components/seo"

import FormContato from "../components/organisms/formContato"

const IndexPage = ({ data, location }) => (
  <Layout title="Contact Us" lang="en" location={location}>
    <SEO title="Contact" />

    <p className="mt-3 mb-5" style={{ maxWidth: "600px" }}>
      Please leave your contact information and some information
      about your project, and we will follow up with you soon.
    </p>

    <FormContato className="mb-5" lang="en" />

    <div className="my-5 py-4"></div>
  </Layout>
)

export default IndexPage

export const query = graphql`
  query {
    equipe: allEquipeYaml {
      nodes {
        name
        description
        linkedin
      }
    }
  }
`
